<template>
  <div class="w-full h-full pt-5 px-3">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex flex-row justify-start items-center gap-4">
          <h1 class="text-xl font-extrabold">Employees</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template>
        <div class="flex flex-col gap-2">
          <template>
            <Card class="mt-6 p-5 flex flex-col gap-5">
              <template>
                <div class="flex mb-6 mr-3" style="justify-content: flex-end">
                  <s-button
                    label="New Delegation"
                    class="text-white button-class"
                    :style="{
                      background: appearance.buttonColor || defaultBTNColor,
                    }"
                    @onClick="priviledgedActions(null, 'create')"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <CardFooter
                  reloadcard
                  @reload="onGetQuery"
                  @sortType="onGetQuery({ sort: $event })"
                  @searchResult="onGetQuery({ search: $event })"
                  @actionModal="onFilter"
                />
              </template>
            </Card>
          </template>
          <template v-if="hasModuleAccess">
            <sTable
              v-if="employees.length || isFetching"
              :headers="headers"
              :items="employees"
              :loading="isFetching"
              :pagination-list="pagination"
              @page="onGetQuery({ page: $event })"
              @itemsPerPage="onGetQuery({ perPage: $event })"
              page-sync
            >
              <template v-slot:item="{ item }">
                <div class="flex whitespace-nowrap" v-if="item.photo">
                  <img
                    class=""
                    :src="item.data.photo"
                    v-if="item.data.photo"
                    alt="profile pic"
                    style="
                      height: 35px;
                      width: 35px;
                      border-radius: 5px;
                      object-fit: cover;
                    "
                  />
                  <div
                    style="height: 35px; width: 35px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold pt-2 w-8 h-8"
                    v-else
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </div>
                  <div class="ml-2 text-sm flex flex-col justify-Start">
                    <span
                      class="font-bold cursor-pointer"
                      style="color: #525252"
                    >
                      {{ item.data.fname }} {{ item.data.lname }}
                    </span>
                    <span class="text-romanSilver text-xs uppercase">
                      {{ item.data.orgFunction }}
                    </span>
                  </div>
                </div>
                <span v-else-if="item.createdAt" class="">
                  {{
                    $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
                  }}
                </span>
                <div v-else-if="item.delegator" class="">
                  <span v-if="Object.hasOwn(item.data.delegator, 'costCentre')">
                    {{ item.data.delegator.name }}
                  </span>
                  <span v-else class="">
                    {{ item.data.delegator.fname }}
                    {{ item.data.delegator.lname }}
                  </span>
                </div>
                <span v-else-if="item.delegatee" class="">
                  {{ item.data.delegatee.fname }}
                  {{ item.data.delegatee.lname }}
                </span>
                <div v-else-if="item.delegationReason" class="">
                  <span v-if="item.data.delegationReason === 'vacant'"
                    >Vacant Position</span
                  >
                  <span v-if="item.data.delegationReason === 'unavailable'"
                    >Unavailable Employee</span
                  >
                  <span v-if="item.data.delegationReason === 'support'"
                    >Support</span
                  >
                </div>
                <span v-else-if="item.delegationStart" class="">
                  {{
                    $DATEFORMAT(
                      new Date(item.data.delegationStart),
                      "MMMM dd, yyyy"
                    )
                  }}
                </span>
                <span v-else-if="item.delegationEnd" class="">
                  {{
                    $DATEFORMAT(
                      new Date(item.data.delegationEnd),
                      "MMMM dd, yyyy"
                    )
                  }}
                </span>
                <span v-else-if="item.initiatedBy" class="">
                  {{ item.data.initiatedBy.fname }}
                  {{ item.data.initiatedBy.lname }}
                </span>
                <p
                  v-else-if="item.status"
                  class="text-center capitalize w-24"
                  :class="{
                    active: item.data.status === 'active',
                    cancelled: item.data.status === 'cancelled',
                    pending: item.data.status === 'pending',
                    closed: item.data.status === 'closed',
                  }"
                >
                  {{ item.data.status }}
                </p>
                <span v-else-if="item.id">
                  <Menu left top="-150" margin="24" class="my-2 p-0">
                    <template v-slot:title>
                      <icon icon-name="more_icon" size="xs" />
                    </template>
                    <div
                      class="w-44 flex flex-col justify-start items-center gap-3 px-3 py-2"
                    >
                      <div
                        class="w-full"
                        @click="priviledgedActions(item.data, 'view_details')"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-eye"
                            class-name="text-darkPurple"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            View Details
                          </p>
                        </div>
                      </div>
                      <div
                        class="w-full"
                        @click="priviledgedActions(item.data.id, 'approval')"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-upgrade"
                            class-name="text-mediumSeaGreen"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            Approval Status
                          </p>
                        </div>
                      </div>
                      <template v-if="item.data.status === 'pending'">
                        <div
                          class="w-full"
                          @click="priviledgedActions(item.data.id, 'cancel')"
                        >
                          <div
                            class="w-full flex justify-start items-center gap-2 cursor-pointer"
                          >
                            <Icon
                              icon-name="icon-unavailable"
                              class-name="text-romanSilver"
                              size="xs"
                            />
                            <p class="font-normal text-sm text-darkPurple">
                              Cancel
                            </p>
                          </div>
                        </div>
                      </template>
                    </div>
                  </Menu>
                </span>
              </template>
            </sTable>
            <div
              v-else
              class="w-full flex flex-col justify-center items-center"
            >
              <icon icon-name="empty_agreement" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center">
                There are no pending delegated employees.
                {{
                  mode === "admin"
                    ? `To view and manage
                employee delegations, kindly click below to start a new employee
                delegation.`
                    : ``
                }}
              </div>
              <div
                @click="priviledgedActions(null, 'create')"
                class="flex justify-start items-center cursor-pointer gap-2 mt-4"
                v-if="mode === 'admin'"
              >
                <Icon icon-name="icon-plus" size="xs" />
                <span class="font-semibold text-base text-flame leading-5">
                  Create new delegation
                </span>
              </div>
            </div>
          </template>
          <div v-else>
            <ErrorComponent />
          </div>
        </div>
      </template>
      <template>
        <Show
          ref="show_delegation"
          :mode="mode"
          @trail="$refs.approval_status.toggle($event)"
        />
        <Create
          @success="onGetQuery('')"
          ref="create_delegation"
          :mode="mode"
          @close="$refs.create_delegation.toggle()"
        />
        <Update
          @success="onGetQuery"
          ref="update_delegation"
          @close="$refs.update_delegation.toggle()"
        />
        <ApprovalProgress ref="approval_status" />
        <FilterTable
          v-if="isOpenFilter"
          :filter-data="filterOptions"
          @close="isOpenFilter = false"
          @getParams="[onGetQuery($event), (isOpenFilter = false)]"
        />
      </template>
    </template>

    <Modal v-if="cancelDialog">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to cancel this delegation process?
          </p>
          <div class="flex">
            <Button
              :background="appearance.buttonColor || defaultBTNColor"
              class="text-white"
              @click="cancelDelegation"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn ml-4 border border-solid border-dynamicBackBtn"
              border="1px solid #F15A29"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import SButton from "@scelloo/cloudenly-ui/src/components/button";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  components: {
    Breadcrumb,
    STable,
    Card,
    SButton,
    CardFooter,
    Menu,
    FilterTable,
    ErrorComponent,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
    Update: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeActions/DelegationManagement/update"
      ),
    Create: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeActions/DelegationManagement/create"
      ),
    Show: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeActions/DelegationManagement/show"
      ),
  },
  data() {
    return {
      isLoading: true,
      isFetching: true,
      isOpenFilter: false,
      filterOptions: [],
      delegationIsLoading: true,
      queryParams: {},
      employees: [],
      pagination: {},
      cancelDialog: false,
      delegationId: "",
      headers: [
        { title: "date created", value: "createdAt" },
        { title: "delegator", value: "delegator" },
        { title: "delegatee", value: "delegatee" },
        { title: "reason for delegation", value: "delegationReason" },
        { title: "start date", value: "delegationStart" },
        { title: "end date", value: "delegationEnd" },
        { title: "initiated by", value: "initiatedBy" },
        { title: "status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Employee",
          href: "EmployeeOverview",
          id: "Employee",
        },
        {
          disabled: false,
          text: "Delegation Management",
          href: "DelegationManagement",
          id: "DelegationManagement",
        },
      ],
      delegator: [],
      delegatee: [],
      reason: [],
      statusOptions: [
        {
          id: "active",
          name: "Active",
        },
        {
          id: "completed",
          name: "Completed",
        },
        {
          id: "pending",
          name: "Pending",
        },
        {
          id: "cancelled",
          name: "Cancelled",
        },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    onFilter() {
      this.filterOptions = [
        { header: "Status", value: "status", optionsBox: this.statusOptions },
      ];
      this.isOpenFilter = true;
    },
    async onGetQuery(params) {
      try {
        if (this.mode === "admin") {
          await this.$handlePrivilege("delegationManagementHR", "viewRegister");
        }
        let queryString = "";
        if (typeof params === "object") {
          queryString = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");
        } else {
          queryString = params;
        }
        this.getOrgEmployeeDelegation(queryString);
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    async priviledgedActions(payload, setAction) {
      try {
        if (setAction === "create") {
          if (this.mode === "admin") {
            await this.$handlePrivilege(
              "delegationManagementHR",
              "createNewDelegation"
            );
          }
          this.$refs.create_delegation.toggle(payload);
        } else if (setAction === "update") {
          if (this.mode === "admin") {
            await this.$handlePrivilege(
              "delegationManagementHR",
              "editDelegationDetails"
            );
          }
          this.$refs.update_delegation.toggle(payload);
        } else if (setAction === "view_details") {
          if (this.mode === "admin") {
            await this.$handlePrivilege(
              "delegationManagementHR",
              "viewDelegationDetails"
            );
          }
          this.$refs.show_delegation.toggle(payload);
        } else if (setAction === "approval") {
          if (this.mode === "admin") {
            await this.$handlePrivilege(
              "delegationManagementHR",
              "viewApprovalStatus"
            );
          }
          this.$refs.approval_status.toggle(payload);
        } else if (setAction === "cancel") {
          if (this.mode === "admin") {
            await this.$handlePrivilege(
              "delegationManagementHR",
              "cancelDelegation"
            );
          }
          this.cancelDialog = true;
          this.delegationId = payload;
        }
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    async getOrgEmployeeDelegation(params) {
      if (this.mode === "admin") {
        this.isFetching = true;
        await this.$_getOrgEmployeeDelegation(params).then(({ data }) => {
          const delegateEmployees = data.data.employees.filter(item => item !== null);
          this.employees = delegateEmployees?.map((delegation) => ({
            ...delegation,
            id: delegation?.delegationdetail.id,
            createdAt: delegation?.delegationdetail.createdAt,
            delegator: delegation?.delegatorInfo,
            delegatee: delegation?.delegateeInfo,
            approvalInitiated: delegation?.delegationdetail.approvalInitiated,
            delegationReason: delegation?.delegationdetail.delegationReason,
            delegationStart: delegation?.delegationdetail.delegationPeriodStart,
            delegationEnd: delegation?.delegationdetail.delegationPeriodEnd,
            initiatedBy: delegation?.initiatedByInfo,
            status: delegation?.delegationdetail.status,
          }));
          this.pagination = data.data.meta;
          this.isFetching = false;
        });
      } else {
        this.isFetching = true;
        await this.$_getManagersEmployeeDelegation(params).then(({ data }) => {
          this.employees = data.data.employees?.map((delegation) => ({
            ...delegation,
            id: delegation.delegationdetail?.id,
            createdAt: delegation.delegationdetail?.createdAt,
            delegator: delegation?.delegatorInfo,
            delegatee: delegation?.delegateeInfo,
            approvalInitiated: delegation.delegationdetail.approvalInitiated,
            delegationReason: delegation.delegationdetail?.delegationReason,
            delegationStart: delegation.delegationdetail?.delegationPeriodStart,
            delegationEnd: delegation.delegationdetail?.delegationPeriodEnd,
            initiatedBy: delegation?.initiatedByInfo,
            status: delegation.delegationdetail?.status,
          }));
          data.data.employees?.forEach((delegation) => {
            this.delegator.push({
              id: delegation.delegatorInfo.id,
              name: delegation.delegatorInfo.name,
            });
          });
          this.pagination = data.data.meta;
          this.isFetching = false;
        });
      }
    },

    cancelDelegation() {
      this.$_cancelDelegation(this.delegationId)
        .then(() => {
          this.cancelDialog = false;
          this.getOrgEmployeeDelegation("");
          this.$toasted.success("Delegation Cancelled successfully", {
            duration: 3000,
          });
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, {
            duration: 3000,
          });
        });
    },
  },
  async created() {
    this.isLoading = true;
    try {
      if (this.mode === "admin") {
        await this.$handlePrivilege("delegationManagementHR", "viewRegister");
      }
      this.hasModuleAccess = true;
      this.getOrgEmployeeDelegation("");
    } catch (error) {
      this.hasModuleAccess = false;
    }

    this.isLoading = false;
  },
};
</script>

<style scoped>
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.active {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.closed {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.cancelled {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.button-class {
  padding: 10px 30px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
</style>
